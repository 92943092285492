var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main login-form register"},[_c('div',{staticClass:"page-content"},[_c('ShopInfo'),(_vm.Common.isExist(_vm.config))?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"title"},[_vm._v("會員註冊")]),_c('el-form',{ref:"user",attrs:{"model":_vm.user}},[_c('el-form-item',{attrs:{"prop":"memEmail","rules":[
                        {
                            validator: _vm.chkAcctExist,
                            trigger: 'blur',
                            required: true,
                        } ]}},[_c('el-input',{attrs:{"placeholder":"電子信箱帳號"},model:{value:(_vm.user.memEmail),callback:function ($$v) {_vm.$set(_vm.user, "memEmail", $$v)},expression:"user.memEmail"}})],1),_c('el-form-item',{attrs:{"prop":"memName","rules":[
                        {
                            trigger: 'blur',
                            required: true,
                            message: _vm.msg.req,
                        } ]}},[_c('el-input',{attrs:{"placeholder":"姓名"},model:{value:(_vm.user.memName),callback:function ($$v) {_vm.$set(_vm.user, "memName", $$v)},expression:"user.memName"}})],1),_c('el-form-item',{attrs:{"prop":"passwd","rules":[
                        {
                            validator: _vm.msg.eleIsPassword,
                            trigger: 'blur',
                            required: true,
                            message: _vm.msg.password,
                        } ]}},[_c('el-input',{attrs:{"show-password":"","placeholder":"密碼"},model:{value:(_vm.user.passwd),callback:function ($$v) {_vm.$set(_vm.user, "passwd", $$v)},expression:"user.passwd"}})],1),_c('el-form-item',{attrs:{"prop":"password2","rules":[
                        {
                            validator: _vm.isPassword2,
                            trigger: 'blur',
                            required: true,
                        } ]}},[_c('el-input',{attrs:{"show-password":"","placeholder":"確認密碼"},model:{value:(_vm.user.password2),callback:function ($$v) {_vm.$set(_vm.user, "password2", $$v)},expression:"user.password2"}})],1),_c('el-checkbox',{staticClass:"agreement",model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}},[_c('span',{staticClass:"agreement-content"},[_vm._v(" 我已閱讀並同意 "),_c('a',{style:({ color: _vm.config.color }),attrs:{"href":_vm.config.privacyTerm,"target":"_blank"}},[_vm._v("服務協議")]),_vm._v(" 與 "),_c('a',{style:({ color: _vm.config.color }),attrs:{"href":_vm.config.serviceTerm,"target":"_blank"}},[_vm._v("隱私政策")])])]),_c('div',{staticClass:"btn-grp"},[_c('el-button',{attrs:{"type":"primary","disabled":!_vm.agreement},on:{"click":_vm.onRegister}},[_vm._v(" 立即註冊 ")])],1),_c('div',{staticClass:"login-form-operate",style:({ color: _vm.config.color })},[_c('router-link',{attrs:{"to":"/account/login","replace":""}},[_vm._v("立即登入")]),_c('router-link',{attrs:{"to":"/account/lookup"}},[_vm._v("忘記密碼")])],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }