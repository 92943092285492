//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Form, FormItem, Input, Button, Checkbox } from "element-ui";
import { mapState, mapActions } from "vuex";
import { ShopInfo } from "@/components";
export default {
    name: "Register",
    components: {
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
        ShopInfo,
    },
    data() {
        return {
            user: {
                memName: "",
                memEmail: "",
                passwd: "",
                password2: "",
            }, //表單驗證信息
            agreement: false,
            info: {
                // color: "#90BE40",
            },
            list: [],
            success: false,
            isExist: false, //電子信箱是否存在
        };
    },
    watch: {},
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
        }),
    },
    watch: {},
    created() {},
    methods: {
        //註冊
        async onRegister(val) {
            this.$refs.user.validate((valid) => {
                if (valid) {
                    const obj = {
                        type: "onRegister",
                        val: { ...this.user },
                    };
                    this.$store.dispatch("base/setVerifySource", obj);
                } else {
                    return false;
                }
            });
        },
        //密碼二次檢驗
        isPassword2(rule, value, callback) {
            if (value === "") {
                callback(new Error("請再次輸入密碼"));
            } else if (value !== this.user.passwd) {
                callback(new Error("兩次輸入的密碼不一致"));
            } else {
                callback();
            }
        },
        //驗證帳號是否存在
        async chkAcctExist(rule, value, callback) {
            const reg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.[_a-z0-9-]+(\.[_a-z0-9-]+)*$/i;
            if (!reg.test(this.user.memEmail || !this.user.memEmail)) {
                callback(new Error("電子信箱格式不正確"));
                return false;
            }

            const obj = {
                type: "chkAcctExist",
                val: { memEmail: this.user.memEmail },
            };
            await this.$store.dispatch("base/setVerifySource", obj).then((isExist) => {
                console.log(isExist);
                if (isExist) {
                    callback(new Error("此帳號已被使用，請輸入其他帳號"));
                }
            });
        },
    },
};
